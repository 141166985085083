import React, { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { useAuth } from '../context/auth'
import { Head } from '../components/Head'
import { LinkBanner } from '../components/LinkBanner'
import { translate } from '../utils/firebaseError'

const Login = () => {
	const router = useRouter()
	const referer = router.query.referer as string

	const { currentFirebaseUser, error, loginWithGoogle, loginWithEmailAndPassword } = useAuth()

	const [isLoading, setIsLoading] = useState(false)
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')

	const handleSignInWithEmailAndPassword = async (): Promise<void> => {
		setIsLoading(true)
		await loginWithEmailAndPassword(email, password)
		setIsLoading(false)
	}

	useEffect(() => {
		if (currentFirebaseUser && !currentFirebaseUser.isAnonymous) {
			router.push(referer || '/')
		}
	}, [currentFirebaseUser?.isAnonymous])

	return (
		<>
			<Head
				title="ログイン"
				description="ナガタクは長町近郊の飲食店からお食事を探して注文できる宅配サービスです。和食・中華・韓国料理・カレー・イタリアン・コーヒー・ケーキ・お酒など様々なジャンルのお店全店舗自由に組み合わせて注文可能です。"
				image="https://www.nagataku.com/logo2.svg"
			/>
			<LinkBanner text="ログイン" back />
			<div className="pt-16 p-6 md:w-[400px] mx-auto">
				<button className="shadow-md rounded-lg px-6 py-3 mt-2 w-full" onClick={loginWithGoogle}>
					<div className="flex items-center justify-center">
						<img className="mr-4 w-6" src="/g-logo.png" alt="Sign in with Google" />
						<div className="font-bold">Googleアカウントでログイン</div>
					</div>
				</button>

				<div className="text-center my-4">または</div>

				<div className="shadow-md rounded-lg mb-4">
					<div className="p-6">
						<div className="mb-2">
							<label htmlFor="email" className="text-lg font-bold">
								メールアドレス
							</label>
							<div>
								<input
									id="email"
									type="email"
									className="rounded-lg border-2 border-orange mt-2 h-12 w-full p-2"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
						</div>

						<div className="mb-2">
							<label htmlFor="password" className="text-lg font-bold">
								パスワード
							</label>
							<div>
								<input
									id="password"
									type="password"
									className="rounded-lg border-2 border-orange mt-2 h-12 w-full p-2 mb-2"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
								<div className="text-gray text-sm">半角英数含む8文字以上</div>
							</div>
						</div>

						{error && <div className="text-red text-md my-4">{translate(error)}</div>}

						<div className="text-gray text-sm mb-4">
							ナガタクの
							<Link href="/terms">
								<a target="_blank" rel="noreferrer" className="text-orange">
									利用規約
								</a>
							</Link>
							と
							<Link href="/privacy">
								<a target="_blank" rel="noreferrer" className="text-orange">
									プライバシーポリシー
								</a>
							</Link>
							に同意いただける場合はログインしてください。
						</div>

						<button
							type="submit"
							className={`shadow-md rounded-lg ${
								isLoading ? 'bg-gray' : 'bg-orange'
							} text-lg text-white font-bold px-6 py-3 w-full mb-4`}
							disabled={isLoading}
							onClick={handleSignInWithEmailAndPassword}
						>
							{isLoading ? '処理中です...' : 'ログインする'}
						</button>

						<div className="text-orange text-sm text-center">
							<Link href="/forgot-password">パスワードを忘れた方はこちら</Link>
						</div>
					</div>
				</div>

				<div className="text-center my-4">
					はじめてナガタクをご利用ですか？
					<span className="text-orange">
						<Link href="/signup">アカウント作成</Link>
					</span>
				</div>
			</div>
		</>
	)
}

export default Login
