import React from 'react'
import { useRouter } from 'next/router'

type Props = {
	text?: string
	path?: string
	color?: string
	back?: boolean
}

export const LinkBanner = (props: Props) => {
	const { text, path, color, back } = props

	const router = useRouter()

	return (
		<div
			className={`z-10 fixed px-4 py-3 text-xl text-center shadow-md w-full bg-${
				color || 'orange'
			} text-white`}
		>
			<div className="flex items-center justify-between md:w-[1040px] md:mx-auto">
				<button onClick={() => (path ? router.push(path) : back ? router.back() : null)}>
					<img className="w-6" src="/arrow-left.svg" alt="arrow-left" />
				</button>
				<div>{text}</div>
				<div className="w-6" />
			</div>
		</div>
	)
}
